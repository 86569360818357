import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { getFluidGatsbyImage } from '../../utils/gatsby-storyblok-image-fixed/';
import { useProductCategories } from '../../hooks/use-product-categories';
import parseStoryblokLink from '../../utils/parse-storyblok-link';
import ProductFilter from '../product-filter';

export default function StoryblokProductsPage({ title, subtitle, paragraph }) {
  const { items } = useStaticQuery(graphql`
    query ProductsQuery {
      items: allStoryblokEntry(
        filter: { field_component: { eq: "product" } }
        sort: { fields: [position] }
      ) {
        edges {
          node {
            position
            name
            full_slug
            content
          }
        }
      }
    }
  `);

  const categories = useProductCategories();

  const itemsParsed = useMemo(() => {
    return items.edges.map((productData) => {
      let product = JSON.parse(productData.node.content);
      const fluidProductImage = getFluidGatsbyImage(product.productThumbnail, {
        maxWidth: 550,
      });

      return {
        image: fluidProductImage,
        name: product.productName,
        link: parseStoryblokLink(productData.node.full_slug),
        position: productData.node.position,
        ...product,
      };
    });
  }, [items]);

  return (
    <ProductFilter
      items={itemsParsed}
      title={title}
      subtitle={subtitle}
      paragraph={paragraph}
      categories={categories}
    />
  );
}
